import './RateRequest1.css'
import ApiService from '../apis/ApiService'
import ExtendedCostRow from './ExtendedCostRow'
import { useCallback, useEffect, useState } from 'react'

const RateRequest1 = () => {
    const [total, setTotal] = useState(0)
    const [params, setParams] = useState([])
    const date = new Date()
    const [extdCost, setExtdCost] = useState({
        "Row1": 0,
        "Row2": 0,
        "Row3": 0,
        "Row4": 0,
        "Row5": 0,
    })

    useEffect(() => {
        async function getDDParams() {
            const response = await ApiService.getParams()
            setParams(response.data)
        }
        getDDParams()
    }, [])

    const handExtdCost = useCallback((instanceId, value) => {
        setExtdCost((preValues) => ({
            ...preValues,
            [instanceId]: value
        }))
    }, [])


    useEffect(() => {
        setTotal(extdCost.Row1 + extdCost.Row2 + extdCost.Row3 + extdCost.Row4 + extdCost.Row5)
    }, [extdCost])

    return (
        <div className='rate-request-main'>
            <h3>HATCHET HARDWARE SCREEN REPAIR CALCUALTOR</h3>
            <table width="65%" cellPadding="1">
                <thead>
                    <tr>
                        <th>Qty</th>
                        <th>Height</th>
                        <th>Width</th>
                        <th>Frame Type</th>
                        <th>Screen Type</th>
                        <th>Special Item</th>
                        {/* <th>Action</th> */}
                        <th>Extd. Cost</th>
                    </tr>
                </thead>
                <tbody>
                    <ExtendedCostRow instanceId="Row1" onValueChange={handExtdCost} ddParams={params} />
                    <ExtendedCostRow instanceId="Row2" onValueChange={handExtdCost} ddParams={params} />
                    <ExtendedCostRow instanceId="Row3" onValueChange={handExtdCost} ddParams={params} />
                    <ExtendedCostRow instanceId="Row4" onValueChange={handExtdCost} ddParams={params} />
                    <ExtendedCostRow instanceId="Row5" onValueChange={handExtdCost} ddParams={params} />
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6">
                            <span style={{ fontSize: 16, color: 'black', paddingRight: 100, justifyContent: 'left' }}> Date : {
                                date.toDateString()
                            }</span>
                            GRAND TOTAL ($) :
                        </td>
                        <td>
                            {total ? total.toFixed(2) : "0.00"}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default RateRequest1