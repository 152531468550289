import React from 'react'

const Select = ({ name, id, onChange, value, valueArray }) => {
    return (
        <td>
            <select name={name} id={id} onChange={onChange} value={value}>
                {valueArray.map(item => <option key={item} value={item} >{item}</option>)}
            </select>
        </td>
    )
}

export default Select