import axios from 'axios'
const baseUrl = "https://eod6emhrn0r39rc.m.pipedream.net"
const apiKey = "shahh3ohnch6lachuFieDieyum5pieNama0ohnguasheet1TuF9DieneiXigh8uo0"
const paramsURL = "https://eoa6cgxlxb30g0v.m.pipedream.net/"

const apiService = axios.create({
    timeout: 20000,
    headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json"
    }
})

const ApiService = {
    requestRate: async (screen) => await apiService.get(baseUrl, {
        params: {
            height: screen.height,
            width: screen.width,
            quantity: screen.quantity,
            frameType: screen.frameType,
            screenType: screen.screenType,
            specialItem: screen.specialItem
        }
    }),
    getParams: async () => apiService.get(paramsURL)
}

export default ApiService
