import './App.css';
import RateRequest1 from './components/RateRequest1'

function App() {
  return (
    <div className="App">
      <RateRequest1 />
    </div>
  );
}

export default App;
