import { useEffect, useState } from 'react'
import ApiService from '../apis/ApiService'
import Select from './Select'

const ExtendedCostRow = ({ instanceId, onValueChange, ddParams }) => {
    const Quantity = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    const Heights = [24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96]
    const Widths = [18, 24, 30, 36, 42, 48, 54, 60]

    // const FrameTypes = ['None (Repair)', '1 inch Bronze', '1 inch Grey', '1 inch Mill', '1 inch White', '3/4 inch Bronze', '3/4 inch Grey', '3/4 inch Mill', '3/4 inch White', 'Lip Frame', 'Stand Off Frame']
    // const ScreenTypes = ['Fiberglass', 'Aluminum', 'Charcoal Aluminum', 'Sun Screen', 'Suntex', 'Pet Screen']
    // const SpecialItems = ['None', 'Knife Latch(4)', 'Plunger Latch(2)', 'Plunger Latch(4)', 'Slide Locks(2)', 'Spreader Bar', 'Spreader Bar (24 inch)', 'Spreader Bar (36 inch)', 'Spreader Bar (48 inch)', 'Spreader Bar (60 inch)', 'Wicket(1)']
    // For testing of git

    const [FrameTypes1, setFrameTypes1] = useState([])
    const [ScreenTypes1, setScreenTypes1] = useState([])
    const [SpecialItems1, setSpecialItems1] = useState([])

    useEffect(() => {
        if (ddParams.params !== undefined) {
            setFrameTypes1(ddParams.params[0].FrameTypes)
            setScreenTypes1(ddParams.params[1].ScreenTypes)
            setSpecialItems1(ddParams.params[2].SpecialItems)
        }
    }, [ddParams])


    const defaultParams = {
        quantity: 0,
        height: 24,
        width: 18,
        frameType: "None (Repair)",
        screenType: "Fiberglass",
        specialItem: "None"
    }
    const [screen, setScreen] = useState(defaultParams)
    const [extendedCost, setExtendedCost] = useState(0)

    const handleChange = (e) => {
        setScreen({
            ...screen, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        getPrice()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen])

    useEffect(() => {
        onValueChange(instanceId, extendedCost)
    }, [instanceId, extendedCost, onValueChange])

    const getPrice = async () => {
        const response = await ApiService.requestRate(screen)
        setExtendedCost(response.data.ExtendedCost)
    }


    return (
        <tr>
            <Select name="quantity" onChange={handleChange} value={screen.quantity} valueArray={Quantity} />
            <Select name="height" onChange={handleChange} value={screen.height} valueArray={Heights} />
            <Select name="width" onChange={handleChange} value={screen.width} valueArray={Widths} />
            <Select name="frameType" onChange={handleChange} value={screen.frameType} valueArray={FrameTypes1} />
            <Select name="screenType" onChange={handleChange} value={screen.screenType} valueArray={ScreenTypes1} />
            <Select name="specialItem" onChange={handleChange} value={screen.specialItem} valueArray={SpecialItems1} />
            {/* <td>
                <button onClick={getPrice}>Get Price</button>
            </td> */}
            <td>
                <label>{extendedCost ? extendedCost.toFixed(2) : "0.00"}</label>
            </td>
        </tr>
    )
}

export default ExtendedCostRow